<template>
  <div>
    <search-block
      title="цепочки для поиска тех. процесса и необходимого отхода"
    >
      <el-row :gutter="10">
        <el-col :span="8">
          <el-select
            
            @change="changeBranch"
            filterable
            class="searchPole"
            v-model="search.branch"
            value-key="id"
            placeholder="Филиал"
          >
            <el-option
              v-for="item in branchesList"
              :key="item.id"
              :label="item.name + ' ' + (item.dateFrom != null || item.dateTo != null ? '(':'') +(item.dateFrom != null ? ('с ' + 
              new Date(item.dateFrom).getFullYear() 
                  + '-' + (9 > new Date(item.dateFrom).getMonth() ? '0'+(new Date(item.dateFrom).getMonth()+1) : (new Date(item.dateFrom).getMonth()+1)) 
                  + '-' + (10 > new Date(item.dateFrom).getDate() ? '0'+new Date(item.dateFrom).getDate():new Date(item.dateFrom).getDate())
                   + '  '): '') + (item.datEd ? (' - по ' + 
              new Date(item.dateTo).getFullYear() 
                  + '-' + (9 > new Date(item.dateTo).getMonth() ? '0'+(new Date(item.dateTo).getMonth()+1) : (new Date(item.dateTo).getMonth()+1)) 
                  + '-' + (10 > new Date(item.dateTo).getDate() ? '0'+new Date(item.dateTo).getDate():new Date(item.dateTo).getDate())) : '') + (item.dateFrom != null || item.dateTo != null ? ')':'')"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select
            @change="changeSubDivision"
            :disabled="subDivisionsList.length === 0"
            
            filterable
            class="searchPole"
            v-model="search.subDivision"
            value-key="id"
            placeholder="Подразделение"
          >
            <el-option
              v-for="item in subDivisionsList"
              :key="item.id"
              :label="item.name + ' ' + (item.dateFrom != null || item.dateTo != null ? '(':'') + (item.dateFrom != null ? ('Активен с ' + 
              new Date(item.dateFrom).getFullYear() 
                  + '-' + (9 > new Date(item.dateFrom).getMonth() ? '0'+(new Date(item.dateFrom).getMonth()+1) : (new Date(item.dateFrom).getMonth()+1)) 
                  + '-' + (10 > new Date(item.dateFrom).getDate() ? '0'+new Date(item.dateFrom).getDate():new Date(item.dateFrom).getDate())
                   + '  '): '') + (item.datEd ? (' - Неактивна с ' + 
              new Date(item.dateTo).getFullYear() 
                  + '-' + (9 > new Date(item.dateTo).getMonth() ? '0'+(new Date(item.dateTo).getMonth()+1) : (new Date(item.dateTo).getMonth()+1)) 
                  + '-' + (10 > new Date(item.dateTo).getDate() ? '0'+new Date(item.dateTo).getDate():new Date(item.dateTo).getDate())) : '')+ (item.dateFrom != null || item.dateTo != null ? ')':'')"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select
            @change="changeDistrict"
            :disabled="districtsList.length === 0"
            
            filterable
            class="searchPole"
            v-model="search.district"
            value-key="id"
            placeholder="Участок"
          >
            <el-option
              v-for="item in districtsList"
              :key="item.id"
              :label="item.name + ' ' + (item.dateFrom != null || item.dateTo != null ? '(':'') + (item.dateFrom != null ? ('Активен с ' + 
              new Date(item.dateFrom).getFullYear() 
                  + '-' + (9 > new Date(item.dateFrom).getMonth() ? '0'+(new Date(item.dateFrom).getMonth()+1) : (new Date(item.dateFrom).getMonth()+1)) 
                  + '-' + (10 > new Date(item.dateFrom).getDate() ? '0'+new Date(item.dateFrom).getDate():new Date(item.dateFrom).getDate())
                   + '  '): '') + (item.datEd ? (' - Неактивен с ' + + 
              new Date(item.dateTo).getFullYear() 
                  + '-' + (9 > new Date(item.dateTo).getMonth() ? '0'+(new Date(item.dateTo).getMonth()+1) : (new Date(item.dateTo).getMonth()+1)) 
                  + '-' + (10 > new Date(item.dateTo).getDate() ? '0'+new Date(item.dateTo).getDate():new Date(item.dateTo).getDate())) : '') + (item.dateFrom != null || item.dateTo != null ? ')':'')"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </search-block>

    <el-table
      v-if="listAllTechProcessesByUnitId.content !== undefined"
      default-expand-all
      :data="listAllTechProcessesByUnitId.content"
    >
      <el-table-column type="expand">
        <template slot-scope="wastes">
          <div
            :key="techProcessHasWasteId"
            v-for="(waste, techProcessHasWasteId) in wastes.row.techProcessWasteDTO"
            
          >
            <el-row :gutter="10" style="margin-bottom: 5px" v-if="(new Date(waste.dateTo)>=new Date() && new Date(waste.dateFrom)<=new Date()) || waste.dateTo == null">
              <el-col :span="20">
                <div v-bind:style="{ color:(waste.datEd||waste.owDatEd||search.branch.datEd||search.subDivision.datEd||search.district.datEd||wastes.row.datEd) ? '#CC6266' : '#2c3e50'}">
                  <b> 
                    {{waste.datEd ? 

                  (waste.wasteInfo.name  + ' - Неактивна с ' + new Date(waste.dateTo).getFullYear() 
                  + '-' + (9 > new Date(waste.dateTo).getMonth() ? '0'+(new Date(waste.dateTo).getMonth()+1) : (new Date(waste.dateTo).getMonth()+1)) 
                  + '-' + (10 > new Date(waste.dateTo).getDate() ? '0'+new Date(waste.dateTo).getDate():new Date(waste.dateTo).getDate()))

                   : (waste.owDatEd ? 
                  (waste.wasteInfo.name  + ' - Отход неактивен с ' + new Date(waste.owDateTo).getFullYear() 
                  + '-' + (9 > new Date(waste.owdateTo).getMonth() ? '0'+(new Date(waste.owDateTo).getMonth()+1) : (new Date(waste.owDateTo).getMonth()+1)) 
                  + '-' + (10 > new Date(waste.owdateTo).getDate() ? '0'+new Date(waste.owDateTo).getDate():new Date(waste.owDateTo).getDate()))
                   :
                   waste.wasteInfo.name + (waste.dateFrom != null ? ' - Активна с ' + new Date(waste.dateFrom).getFullYear() 
                  + '-' + (9 > new Date(waste.dateFrom).getMonth() ? '0'+(new Date(waste.dateFrom).getMonth()+1) : (new Date(waste.dateFrom).getMonth()+1)) 
                  + '-' + (10 > new Date(waste.dateFrom).getDate() ? '0'+new Date(waste.dateFrom).getDate():new Date(waste.dateFrom).getDate()):"")) 
                  + (waste.owdateFrom != null ? ' - Отход активен с ' + new Date(waste.owdateFrom).getFullYear() 
                  + '-' + (9 > new Date(waste.owdateFrom).getMonth() ? '0'+(new Date(waste.owdateFrom).getMonth()+1) : (new Date(waste.owdateFrom).getMonth()+1)) 
                  + '-' + (10 > new Date(waste.owdateFrom).getDate() ? '0'+new Date(waste.owdateFrom).getDate():new Date(waste.owdateFrom).getDate()):"")
                   }}</b>
                </div>
              </el-col>

              <el-col :span="4">
                <el-button
                  @click="
                    editWaste(
                      waste.techProcessHasWasteId,
                      waste.organizationWasteId
                    )
                  "
                  size="mini"
                  type="primary"
                  >Добавить</el-button
                >
              </el-col>
            </el-row>
            <br />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Тех процесс" prop="email">
        <template slot-scope="row">
          <div v-bind:style="{ color:(row.row.datEd||search.branch.datEd||search.subDivision.datEd||search.district.datEd) ? '#CC6266' : '#606266'}">
          {{ row.row.processName }} {{ row.row.path }}{{(row.row.dateFrom != null ? (' - Активна с ' + new Date(row.row.dateFrom).getFullYear() 
                  + '-' + (9 > new Date(row.row.dateFrom).getMonth() ? '0'+(new Date(row.row.dateFrom).getMonth()+1) : (new Date(row.row.dateFrom).getMonth()+1)) 
                  + '-' + (10 > new Date(row.row.dateFrom).getDate() ? '0'+new Date(row.row.dateFrom).getDate():new Date(row.row.dateFrom).getDate())+' ') : '') + (row.row.datEd ? (' - Неактивна с ' + new Date(row.row.dateTo).getFullYear() 
                  + '-' + (9 > new Date(row.row.dateTo).getMonth() ? '0'+(new Date(row.row.dateTo).getMonth()+1) : (new Date(row.row.dateTo).getMonth()+1)) 
                  + '-' + (10 > new Date(row.row.dateTo).getDate() ? '0'+new Date(row.row.dateTo).getDate():new Date(row.row.dateTo).getDate()) ) : '')}}
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SearchBlock from "@/components/searchBlock";

export default {
  name: "dataEntryTable",
  components: { SearchBlock },
  data() {
    return {
      
      branchesList: [],
      subDivisionsList: [],
      districtsList: [],
      lastSelectedUnitId: null,
      search: {
        branch: {
          id: null,
          name: null,
        },
        subDivision: {
          id: null,
          name: null,
        },
        district: {
          id: null,
          name: null,
        },
        techProcess: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      unitId: "GETSELECTEDUNITID",
      listAllTechProcessesByUnitId: "GETLISTALLTECHPROCESSBYUNITID",
      listOfOrganizationsForSearch: "GETLISTOFORGANIZATIONSFORSEARCH",
    }),
  },

  methods: {
    changeBranch() {
      this.lastSelectedUnitId = this.search.branch.id;
      let Dat = new Date();
      this.getUnitList(2, this.search.branch.id).then((response) => {
        this.subDivisionsList=[];
        response.data.forEach(element => {
          if((new Date(element.dateFrom) < Dat || element.dateFrom == null) && (new Date(element.dateTo) > Dat || element.dateTo==null))
            this.subDivisionsList.push(element);
        });
        this.subDivisionsList.push({"id": 0, "name": "Пропустить"});
      });
      this.getUnitList(3, this.search.branch.id).then((response) => {
        this.districtsList=[];
        response.data.forEach(element => {
          if((new Date(element.dateFrom) < Dat || element.dateFrom == null) && (new Date(element.dateTo) > Dat || element.dateTo==null))
            this.districtsList.push(element);
        });
        this.districtsList = response.data;
      });
      this.getListAllTechProcessesByUnitId({
        page: 0,
        size: 100,
        unitId: this.search.branch.id,
      });
    },

    changeSubDivision() {
      this.lastSelectedUnitId = this.search.subDivision.id;
      let Dat = new Date();
      this.getUnitList(3, this.search.subDivision.id).then((response) => {
        this.districtsList=[];
        response.data.forEach(element => {
          if((new Date(element.dateFrom) < Dat || element.dateFrom == null) && (new Date(element.dateTo) > Dat || element.dateTo==null))
            this.districtsList.push(element);
        });
      });
      this.getListAllTechProcessesByUnitId({
        page: 0,
        size: 100,
        unitId: this.search.subDivision.id,
      });
    },

    changeDistrict() {
      this.lastSelectedUnitId = this.search.district.id;
      this.getListAllTechProcessesByUnitId({
        page: 0,
        size: 100,
        unitId: this.search.district.id,
      });
    },

    async getListAllTechProcessesByUnitId(data) {
      await this.$store.dispatch("getListAllTechProcessesByUnitId", data);
      let Dat = new Date();

      let TempList = [];
      this.listAllTechProcessesByUnitId.content.forEach(element => {
        if((new Date(element.dateFrom) < Dat || element.dateFrom == null) && (new Date(element.dateTo) > Dat || element.dateTo==null))
          TempList.push(element);
      });
      this.listAllTechProcessesByUnitId.content=TempList;      
    },

    editWaste(processId, wasteId) {
      this.$router.push({ path:"edit/" + processId + "/" + wasteId + "/" + this.lastSelectedUnitId,date:wasteId});
    },

    searchTechProcess() {
      this.getListAllTechProcessesByUnitId({
        page: 0,
        size: 20,
        unitId: this.search.id,
      });
    },

    getListOfOrganizationsForSearch() {
      this.$store.dispatch("getListOfOrganizationsForSearch");
    },

    async getUnitList(unitType, unitId) {
      return await this.$store.dispatch("organizationUnitsForSelect", {
        unitType: unitType,
        unitId: unitId,
      });
    },
  },

  mounted() {
    this.listAllTechProcessesByUnitId.content = undefined;
    this.getListOfOrganizationsForSearch();
    let Dat = new Date();
    this.getUnitList(1, 0).then((response) => {
      this.branchesList=[];
      response.data.forEach(element => {
        if((new Date(element.dateFrom) < Dat || element.dateFrom == null) && (new Date(element.dateTo) > Dat || element.dateTo==null))
          this.branchesList.push(element);
      });
      this.branchesList.push({"id": 0, "name": "Пропустить"});
    });
    this.getUnitList(2, 0).then((response) => {
      this.subDivisionsList=[];
      response.data.forEach(element => {
        if((new Date(element.dateFrom) < Dat || element.dateFrom == null) && (new Date(element.dateTo) > Dat || element.dateTo==null))
          this.subDivisionsList.push(element);
      });
    });
    this.getUnitList(3, 0).then((response) => {
      this.districtsList=[];
      response.data.forEach(element => {
        if((new Date(element.dateFrom) < Dat || element.dateFrom == null) && (new Date(element.dateTo) > Dat || element.dateTo==null))
          this.districtsList.push(element);
      });
    });
  },
};
</script>

<style scoped></style>
